import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';
import css from './ListingPage.module.css';
import { formatMoney } from '../../util/currency';
import { types } from 'sharetribe-flex-sdk';
const { Money } = types;
const SectionExtraEquipment = props => {
  const { extraEquipment,intl } = props;
  const [isExtraEquipmentVisible, setIsExtraEquipmentVisible] = useState(false);

  const toggleExtraEquipmentVisibility = () => {
    setIsExtraEquipmentVisible(!isExtraEquipmentVisible);
  };

  return (
    <div className={css.sectionExtraEquipment}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin} onClick={toggleExtraEquipmentVisibility}>
        {isExtraEquipmentVisible ? <div className={css.bottomDirArrow}>&gt;</div> : <div className={css.bottomDirArrowUp}>&gt;</div>}
        <FormattedMessage id="ListingPage.extraEquipment" />
      </Heading>
      <div className={css.extraEquipment}>
      {isExtraEquipmentVisible && (
        <>
          {extraEquipment.map((item, index) => (
            <div key={`accessory-${index}`} className={css.accessoryContainer}>
              <h2 className={css.accessoriesName}>{item.accessoryName}</h2>
              <hr />
              <div className={css.accessoriesDetails}>
                <div className={css.accessoriesPrice}>
                {item.accessoryExtraInfo && <p>{item.accessoryExtraInfo}</p>}
                   {/* {item.accessoryExtraInfo!==null&&<p>{item.accessoryExtraInfo}</p>}  */}
                <p ><b>Price</b> (per day): {formatMoney(intl, new Money(item.accessoryPrice, 'DKK'))} </p>
                </div>
                <img src={item.accessoryImageBlobUrl} alt="Accessory Image" className={css.accessoriesImage} />
              </div>
            </div>
          ))}
        </>
      )}
      </div>
    </div>
  );
};

export default SectionExtraEquipment;